<template>
  <v-card>
    <v-card-title>
      <span class="headline">Registro de CPT</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-textarea auto-grow :readonly="!isHealthTeam" v-model="editedItem.cpt"> </v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="close()">
            Fechar
          </v-btn>
          <v-btn
            v-if="isHealthTeam"
            color="green darken-1"
            :disabled="hasChanges"
            text
            @click="update()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card-text>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { updateDps } from "@/graphql/mutations";
import { API } from "aws-amplify";
import { assign, isEqual } from "lodash";
export default {
  name: "CPT",
  props: ["toggle", "item"],
  data() {
    return {
      editedItem: {
        cpt: ""
      }
    };
  },
  mounted() {
    assign(this.editedItem, this.item);
  },
  watch: {
    item(item) {
      assign(this.editedItem, item);
    }
  },
  computed: {
    isHealthTeam() {
      return this.$store.state.user.isHealthTeam;
    },
    hasChanges() {
      return isEqual(this.item, this.editedItem);
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  methods: {
    close() {
      if (this.toggle) this.toggle();
    },
    async update() {
      try {
        this.$store.commit("loading", true);
        await API.graphql({
          query: updateDps,
          variables: {
            input: this.editedItem
          }
        });

        this.item.cpt = this.editedItem.cpt;
      } catch (error) {
        this.showAlert("error", "Erro ao atualizar CPT");
        this.editedItem.cpt = this.item.cpt;
      } finally {
        this.$store.commit("loading", false);
      }

      this.close();
    },
    showAlert(type, message) {
      this.$store.commit("showAlert", { type, message });
    }
  }
};
</script>
